<template>
  <div>
    Something went wrong, please try to login again
  </div>
</template>

<script>
import router from "../router";
export default {
  created() {
    this.redirect();
  },
  methods: {
    redirect() {
      alert("Something went wrong, please try to login again");
      router.push({ path: "/" });
    }
  }
};
</script>
